<template>
  <div class="home">
    <Section light>
      <h1>Sponsorships</h1>
      <v-col cols="12">
        <p>
          We're a team of dedicated and passionate students looking to leave a
          positive impact on the next generation of engineers and scientists by
          giving invaluable hands-on experience in a competitive environment. We
          do our best with the resources we have, but we require external
          support to affort the materials, parts, and travel costs associated
          with this prestigous competition. As a a
          <a
            href="https://apps.irs.gov/app/eos/detailsPage?ein=815281713&name=Yonder%20Dynamics&city=San%20Diego&state=CA&countryAbbr=US&dba=&type=CHARITIES,%20DETERMINATIONLETTERS,%20EPOSTCARD,%20REVOCATION&orgTags=CHARITIES&orgTags=DETERMINATIONLETTERS&orgTags=EPOSTCARD&orgTags=REVOCATION"
            >registered 509(a)(2) nonprofit</a
          >, all contributions to us are tax deductible. If you are interested
          in supporting us on our mission, you can find more details about our
          team and the benefits in our
          <a href="/YonderDynamics20232024SponsorshipPackage.pdf"
            >sponsorship packet</a
          >. Feel free to email us with any questions:
          <a href="mailto:yonder.dynamics@ucsd.com">yonder.dynamics@ucsd.com</a
          >.
        </p>
        <h1>Direct donations</h1>
        <p>
          In addition to corporate sponsorships, we are happy to accept direct
          donations from any interested individuals. Direct donations are also
          tax-deductible - donors can find our nonprofit details for filing
          purposes
          <a
            href="https://apps.irs.gov/app/eos/detailsPage?ein=815281713&name=Yonder%20Dynamics&city=San%20Diego&state=CA&countryAbbr=US&dba=&type=CHARITIES,%20DETERMINATIONLETTERS,%20EPOSTCARD,%20REVOCATION&orgTags=CHARITIES&orgTags=DETERMINATIONLETTERS&orgTags=EPOSTCARD&orgTags=REVOCATION"
            >here</a
          >.
        </p>
        <div class="col-md-8 offset-md-2 col-xs-12">
          <form
            action="https://www.paypal.com/cgi-bin/webscr"
            id="donate-form"
            method="post"
            target="_top"
            style="margin: 0 auto"
          >
            <input type="hidden" name="cmd" value="_s-xclick" />
            <input
              type="hidden"
              name="hosted_button_id"
              value="6FEM5ZP8RY2W2"
            />
            <div id="cool-button">
              <h3 id="rainbowy" className="unselectable noselect">Donate</h3>
            </div>
          </form>
        </div>
      </v-col>
    </Section>
    <Sponsors />
  </div>
</template>

<script>
import Section from '@/components/Section.vue';
import Sponsors from '@/components/Sponsors.vue';

export default {
  name: 'Mechanical',
  components: {
    Section,
    Sponsors,
  },
  mounted() {
    document.getElementById('rainbowy').onclick = () => {
      document.getElementById('donate-form').submit();
    };

    let angle = 0;
    const p = document.getElementById('rainbowy');
    const text = p.innerHTML.split('');
    const len = text.length;
    const phaseJump = 360 / len;

    p.innerHTML = text.map((char) => `<span>${char}</span>`).join('');

    const spans = p.children;

    const change = () => {
      for (let i = 0; i < len; i += 1) {
        spans[i].style.color = `hsl(${
          angle + Math.floor(i * phaseJump)
        }, 100%, 70%)`;
      }
      angle += 1;
      requestAnimationFrame(change);
    };
    change();
  },
};
</script>

<style scoped>
h1 {
  /* color: black; */
}
p {
  /* color: black; */
}

a {
  text-decoration: underline;
  color: white;
}
a:link {
  color: white;
}
a:hover {
  text-decoration: none;
}

#cool-button {
  margin-top: 0px;
  border-radius: 5px;
  background-color: var(--medium);
  -webkit-transition: background-color 0.3s ease-out;
  -moz-transition: background-color 0.3s ease-out;
  -o-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out;
}

#cool-button:hover {
  background-color: var(--dark) !important;
}

h3 {
  padding-top: 15px;
  padding-bottom: 15px;
  font-family: 'Bungee Shade', cursive !important;
  color: black;
  font-size: 3em;
  text-align: center;
}
</style>
